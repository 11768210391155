@import '~application/src/scss/mixins';
@import '~application/src/scss/variables';
@import '~application/src/scss/settings';
@import 'foundation-sites/scss/foundation';

user-messages {
  &>.outer {
    min-height: 60px;
    position: relative;
  }

  .textarea {
    margin-bottom: 0.25rem;
  }

  textarea {
    display: none !important;
  }

  .no-results-message {
    display: none;
  }

  .new-msg-info {
    position: absolute;
    top: 4px;
    background-color: $mundus-agri-color;
    color: $white;
    font-size: 0.75rem;
    padding: 0.35rem 0.5rem;
    line-height: 1;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;

    &::before {
      @include css-triangle(4px, $mundus-agri-color, up);
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -3px;
    }
  }

  .filter-menu {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &>div {
      flex: 1 1 100%;
    }

    input {
      margin: 0;
    }

    @include breakpoint(480px) {
      flex-wrap: nowrap;

      &>div {
        flex: 0 1 auto;

        &:first-of-type {
          margin-right: 1rem;
        }
      }
    }
  }

  .list {
    position: relative;

    &[style^="max-height"] {
      @include overflowScrollY();
      scroll-behavior: initial;
    }
  }

  .message {
    position: relative;
    margin-bottom: 1rem;
    background-color: $white;
    border-left: 0.5rem solid $mundus-agri-color;
    border-right: 1px solid $medium-gray;
    border-bottom: 1px solid $medium-gray;
    border-top: 1px solid $medium-gray;

    &.read:not(.has-unread) {
      border-left-color: lighten($medium-gray, 3%);
    }

    .show-reply-textarea,
    .show-replies,
    .hide-replies {
      user-select: none;
      white-space: nowrap;
    }

    .flyout-menu {
      position: absolute;
      top: 0;//1rem;
      right: 0;//.25rem;
    }

    .textarea,
    .msg-buttons {
      flex: 1 1 100%;
    }

    &>.msg-head {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      padding: 1rem 1rem 0.5rem;
      user-select: none;

      .top {
        display: flex;
        flex-direction: column;

        span {
          display: inline-block;
          color: $white;
          background-color: lighten(get-color(success), 10%);
          padding: 1px 3px;
          font-size: 10px;
          line-break: 10px;
          margin-bottom: 0.5rem;
        }
      }

      .num-replies {
        font-size: 0.75rem;
        color: $medium-gray;
      }
    }

    .msg-body {
      padding: 1rem 1rem 1.5rem;

      &>.msg-outer-content {
        padding: 0 0 1rem;
      }
    }

    .msg-content {
      position: relative;
      display: flex;
      align-items: flex-start;
    }

    .show-reply-textarea {
      margin-right: 1.5rem;
      user-select: none;

      i {
        margin-right: 0.15rem;
      }
    }

    .company-name {
      font-size: 0.75rem;
      font-weight: 700;
    }

    .date {
      color: $medium-gray;
      font-size: 0.75rem;
      display: block;
    }

    .profile-image {
      display: block;
      position: relative;
      z-index: 1;

      a {
        width: 45px;
        height: 45px;
        border-radius: 200px;
        margin-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center center;
        background-size: cover;
        transition: background-color 150ms ease-in-out;

        i {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: -1;
          width: 45px;
          height: 45px;
          border-radius: 200px;
          color: $white;
          background-color: $light-gray;
          font-size: 1.25rem;
        }
      }
    }

    .profile-info {
      flex: 0 1 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      a:first-of-type {
        line-height: 1;
      }
    }

    .post-menu {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .upper {
        margin-bottom: 0.5rem;
      }

      .button {
        &:not(:first-child) {
          margin-left: 0.25rem;
        }
      }
    }

    .msg-attachments {
      max-width: 600px;
      background-color: lighten($light-gray, 3%);
      margin-top: 1rem;

      &>div {
        &:not(:last-of-type) {
          margin-bottom: 0.25rem;
        }
      }
    }

    .trade-item {
      padding: 1rem;
    }
  }

  .reply {
    position: relative;
    background-color: $white;
    border: 1px solid $light-gray;
    padding: 1rem;
    margin-bottom: 0.5rem;

    .show-reply-textarea,
    .show-replies,
    .hide-replies {
      font-size: 0.9rem;
      user-select: none;
      white-space: nowrap;
    }

    &>.msg-head {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      &>div:nth-of-type(2) {
        flex: 1;
      }
    }

    .msg-body {
      &>.msg-outer-content {
        margin-bottom: 1rem;
      }
    }

    @include breakpoint(800px) {
      padding: 1rem;
    }
  }

  .enter-reply {
    margin-top: 1rem;
  }

  .send-message {
    margin-bottom: 1rem;

    .textarea {
      margin-bottom: 0.25rem;
      background: $white;
    }
  }

  &[appearance=minimized],
  &[appearance=integrated] {
    .message {
      margin-bottom: 0;

      &:not(:first-of-type) {
        border-top: 0;
      }

      &>.msg-head {
        cursor: pointer;
        margin-bottom: 0;
        padding-top: 0.5rem;
        padding-right: 2rem;
        flex-wrap: wrap;
        transition: background-color 150ms ease-in-out;

        &::after {
          content: '';
          display: block;
          width: 6px;
          height: 6px;
          border-bottom: 1px solid $medium-gray;
          border-left: 1px solid $medium-gray;
          transform: rotate(-45deg);
          position: absolute;
          right: 0.75rem;
          top: 1rem;
          margin-top: -2px;
        }
      }

      &.open {
        &>.msg-head {
          background-color: $mundus-agri-color-light;

          &::after {
            transform: rotate(135deg);
          }
        }
      }

      &>.msg-head:hover,
      &.open>.msg-head {
        background-color: $mundus-agri-color-light;

        .profile-image i {
          background-color: $medium-gray;
        }
      }

      .top-content {
        flex: 1 1 100%;
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        padding-left: calc(30px + 0.5rem);

        div {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          flex: 1 1 100%;
        }
      }

      &>.msg-body {
        position: relative;
        padding: 0;
        background-color: lighten($mundus-agri-color-light, 4%);

        &>.msg-outer-content {
          padding: 1rem;
          background-color: $white;
          border-bottom: 1px solid $light-gray;
        }
      }

      .msg-content {
        .msg-subject {
          flex: 1 1 100%;
        }

        .msg-text {
          flex: 1 1 auto;
        }

        .flyout-menu {
          margin-left: 0.5rem;
          position: relative;
          top: 0;
          right: 0;
        }
      }

      .profile-info {
        flex: 1;
      }

      .profile-image {
        a,
        i {
          width: 30px;
          height: 30px;
        }
      }

      .outer-replies {
        padding: 1rem;
      }

      .reply {
        .profile-info {
          width: auto;
        }
      }
    }

    .lower-replies {
      .textarea {
        background-color: lighten($mundus-agri-color-light, 5%);
      }
    }

    @include breakpoint(600px) {
      .message {
        .profile-info {
          display: block;
          width: 175px;
          flex: 0 1 auto;

          a {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
          }
        }

        .profile-image {

          a,
          i {
            width: 40px;
            height: 40px;
          }
        }

        .top-content {
          flex: 1;
          border-left: 1px dotted $light-gray;
          padding-left: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  &[appearance=integrated] {
    .message {
      border-right: 0;
      border-top: 0;
      border-bottom-style: dotted;
    }
  }
}